import React, { useEffect, useState, lazy } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { db } from './auth/FirebaseAuth';
import ErrorOne from './underMaintenance';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {

  // const [maintenance, setMaintenance] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
  db.collection('settings').doc('global').onSnapshot((doc) => {
    if (doc.exists) {
      var globalData = doc.data();
      console.log('MAINTENANCE MODE IN APP js', globalData.mMode);
      // setMaintenance(globalData.mMode);

      // console.log('M Mode State', maintenance);

      if (localStorage.getItem('mModeCheck') == null) {
        localStorage.setItem('mModeCheck', 'false')
      }

      if (globalData.mMode == true && localStorage.getItem('mModeCheck') == 'false') {
        console.log('MAINTENANCE MODE IN APP js 2', globalData.mMode);
        window.location = '/error';
        localStorage.setItem('mModeCheck', 'true');
      }
      else if (globalData.mMode == false && localStorage.getItem('mModeCheck') == 'true'){
        console.log('MAINTENANCE MODE IN APP js 3', globalData.mMode);
        window.location = '/';
        localStorage.setItem('mModeCheck', 'false');
      }
      // mMode = globalData.mMode;
    }
  })
  
  }

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <Router>
            <Switch>
              <Route path="/error" component={ErrorOne} />
              <Route path="/" component={Views}/>
              {/* {maintenance ?
                <Redirect to={'/error'}/>
                :
                <Redirect to={'/'}/> 
              } */}
              {/* <Route path="/" render={() => (
                maintenance == false ? (
                  <Redirect to="/" />
                ) : (
                  <ErrorOne />
                )
              )} /> */}
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
