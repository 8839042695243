const FirebaseConfig = {
  apiKey: "AIzaSyBy8lbU0dLn-L8FCa8Mnrx5yX2Knid5_M0",
  authDomain: "sia-knrfbd.firebaseapp.com",
  databaseURL: "https://sia-knrfbd.firebaseio.com",
  projectId: "sia-knrfbd",
  storageBucket: "sia-knrfbd.appspot.com",
  messagingSenderId: "460716293545",
  appId: "1:460716293545:web:250814a3007927100898b8",
  measurementId: "G-RWJ86L315B"
};

export default FirebaseConfig

