import { 
  DashboardOutlined,
  ProfileOutlined,
  WechatOutlined,
  LineChartOutlined,
  ShoppingOutlined, 
  FundViewOutlined,
  RedditOutlined,
  ReadOutlined,
  AppstoreOutlined,
  BookOutlined,
  DotChartOutlined,
  GroupOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const userData = JSON.parse(localStorage.getItem('userData'));

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard.overview',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    // {
    //   key: 'dashboards-anaytics',
    //   path: `${APP_PREFIX_PATH}/dashboards/analytics`,
    //   title: 'sidenav.dashboard.analytics',
    //   icon: DotChartOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: 'dashboards-portfolio',
      path: `${APP_PREFIX_PATH}/dashboards/portfolio`,
      title: 'sidenav.dashboard.portfolio',
      icon: AppstoreOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-orders',
      path: `${APP_PREFIX_PATH}/dashboards/orders`,
      title: 'sidenav.dashboard.orders',
      icon: BookOutlined,
      breadcrumb: false,
      submenu: []
    }
    // ,{
    //   key: 'dashboards-chart',
    //   path: `${APP_PREFIX_PATH}/dashboards/chart`,
    //   title: 'sidenav.dashboard.chart',
    //   icon: LineChartOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // }
  ]
}]

const tradingNavTree = [{
  key: 'trading',
  path: `${APP_PREFIX_PATH}/trading`,
  title: 'sidenav.dashboard.trading',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    // {
    //   key: 'trading-manual',
    //   path: `${APP_PREFIX_PATH}/trading/watchlist`,
    //   title: 'sidenav.dashboard.manualTrading',
    //   icon: FundViewOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: 'trading-manual',
    //   path: `${APP_PREFIX_PATH}/trading/manual`,
    //   title: 'sidenav.dashboard.manualTrading',
    //   icon: ProfileOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: 'trading-algo',
    //   path: `${APP_PREFIX_PATH}/trading/algo`,
    //   title: 'sidenav.dashboard.algoTrading',
    //   icon: ProfileOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: 'trading-bots',
      path: `${APP_PREFIX_PATH}/trading/bots`,
      title: 'sidenav.dashboard.botTrading',
      icon: RedditOutlined,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: 'trading-copy',
    //   path: `${APP_PREFIX_PATH}/trading/copy`,
    //   title: 'sidenav.dashboard.copyTrading',
    //   icon: ProfileOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // }
  ]

}]

const researchNavTree = [{
  key: 'research',
  path: `${APP_PREFIX_PATH}/research`,
  title: 'sidenav.dashboard.research',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'research-news',
      path: `${APP_PREFIX_PATH}/research/news`,
      title: 'sidenav.dashboard.news',
      icon: ReadOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'research-glance',
      path: `${APP_PREFIX_PATH}/research/glance`,
      title: 'sidenav.dashboard.glance',
      icon: GroupOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const communityNavTree = [{
  key: 'Community',
  path: `${APP_PREFIX_PATH}/community`,
  title: 'sidenav.dashboard.community',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'community-chat',
      path: `${APP_PREFIX_PATH}/community/chat`,
      title: 'sidenav.dashboard.chat',
      icon: WechatOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]



//nav tree config for demo application

const demoDashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/demo/dashboards`,
  title: 'sidenav.dashboard.overview',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    // {
    //   key: 'dashboards-anaytics',
    //   path: `${APP_PREFIX_PATH}/dashboards/analytics`,
    //   title: 'sidenav.dashboard.analytics',
    //   icon: DotChartOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: 'dashboards-portfolio',
      path: `${APP_PREFIX_PATH}/demo/dashboards/portfolio`,
      title: 'sidenav.demo.dashboard.portfolio',
      icon: AppstoreOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-orders',
      path: `${APP_PREFIX_PATH}/demo/dashboards/orders`,
      title: 'sidenav.demo.dashboard.orders',
      icon: BookOutlined,
      breadcrumb: false,
      submenu: []
    }
    // ,{
    //   key: 'dashboards-chart',
    //   path: `${APP_PREFIX_PATH}/demo/dashboards/chart`,
    //   title: 'sidenav.dashboard.chart',
    //   icon: LineChartOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // }
  ]
}]

const demoTradingNavTree = [{
  key: 'trading',
  path: `${APP_PREFIX_PATH}/trading`,
  title: 'sidenav.dashboard.trading',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    // {
    //   key: 'trading-manual',
    //   path: `${APP_PREFIX_PATH}/demo/trading/watchlist`,
    //   title: 'sidenav.demo.dashboard.manualTrading',
    //   icon: FundViewOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: 'trading-manual',
    //   path: `${APP_PREFIX_PATH}/demo/trading/manual`,
    //   title: 'sidenav.demo.dashboard.manualTrading',
    //   icon: ProfileOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: 'trading-algo',
    //   path: `${APP_PREFIX_PATH}/trading/algo`,
    //   title: 'sidenav.demo.dashboard.algoTrading',
    //   icon: ProfileOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: 'trading-bots',
      path: `${APP_PREFIX_PATH}/demo/trading/bots`,
      title: 'sidenav.demo.dashboard.botTrading',
      icon: RedditOutlined,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: 'trading-copy',
    //   path: `${APP_PREFIX_PATH}/trading/copy`,
    //   title: 'sidenav.demo.dashboard.copyTrading',
    //   icon: ProfileOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // }
  ]

}]

var navigationConfig = []

if(userData != null) {
  if (userData.liveMode == false) {
    navigationConfig = [
      ...demoDashBoardNavTree,
      ...demoTradingNavTree,
      ...researchNavTree,
      ...communityNavTree
    ]
  }
  else {
    navigationConfig = [
      ...dashBoardNavTree,
      ...tradingNavTree,
      ...researchNavTree,
      ...communityNavTree
    ]
  }
}
else {
  navigationConfig = [
    ...dashBoardNavTree,
    ...tradingNavTree,
    ...researchNavTree,
    ...communityNavTree
  ]
}

// const navigationConfig = [
//   ...dashBoardNavTree,
//   ...tradingNavTree,
//   ...researchNavTree,
//   ...communityNavTree
// ]

export default navigationConfig;
