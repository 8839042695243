import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import firebaseConfig from 'configs/FirebaseConfig';
import 'firebase/messaging';
import 'firebase/database';



const app = firebase.initializeApp(firebaseConfig);

//const messaging = getMessaging(); //error
// const messaging = firebase.messaging();

// firebase utils
const rdb = firebase.database();
const db = firebase.firestore()
const auth = firebase.auth();
const analytics = firebase.analytics();
const currentUser = auth.currentUser
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
const messaging = firebase.messaging.isSupported() ? firebase.messaging(app) : null;

export {
	rdb,
	db,
	auth,
	currentUser,
	analytics,
	googleAuthProvider,
	facebookAuthProvider,
	twitterAuthProvider,
	githubAuthProvider,
	phoneAuthProvider,
	firebase,
	messaging
};